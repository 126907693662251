@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    padding: 0;
    margin: 0;
    background-color: #1f1f1f;
}

h1 {
    @apply text-secondary;
}
